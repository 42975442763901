<template>
  <section
    id="about"
    :style="[
      $vuetify.breakpoint.width <= 959
        ? { marginBottom: '471px' }
        : { marginBottom: '575px' }
    ]"
  >
    <v-container>
      <v-layout class="my-5" row wrap align-center justify-center>
        <v-flex xs12>
          <div class="header_aboutme mobileleft">
            <span class="main-text">Itay Blayer - Product Designer</span>
            <span class="sub-text mt-2 mb-5">
              I’m Itay Blayer. 26 y-o, based in Tel Aviv.
            </span>
          </div>
        </v-flex>
      </v-layout>
      <v-layout class="my-5 mbbig" row wrap align-center justify-center>
        <v-flex xs12 md6>
          <p
            :style="textPadding"
            class="about-me-text text-right mb-50 margin-left"
          >
            Tel Aviv is a great city to live in. It offers creatives like me -
            to meet up with other great designers, learn from others, and to
            grow fast as a product designer.
          </p>
        </v-flex>
        <v-flex xs12 md6>
          <v-img
            v-if="$vuetify.breakpoint.width <= '960'"
            class="ml-5 mobile-m-right"
            contain
            :src="about1M"
            alt="Itay's pictture"
          ></v-img>
          <v-img
            v-else
            class="mx-auto"
            max-width="525px"
            max-height="481px"
            contain
            :src="about1"
            alt="Itay's pictture"
          ></v-img>
        </v-flex>
      </v-layout>
      <v-layout class="my-5 mbbig" row wrap align-center justify-center>
        <v-flex xs12 md6 order-xs1>
          <v-img
            v-if="$vuetify.breakpoint.width <= '960'"
            class="mobile-m-left"
            contain
            :src="about2M"
            alt="Itay's pictture"
          ></v-img>
          <v-img
            v-else
            class="mx-auto"
            max-width="636px"
            max-height="411px"
            contain
            :src="about2"
            alt="Itay's pictture"
          ></v-img>
        </v-flex>
        <v-flex xs12 md6 order-md1>
          <p
            :style="textPadding"
            class="about-me-text text-left margin-right mb-50"
          >
            The best part of my job is using MY BRAIN. User interfaces make you
            face complex issues on a daily basis, and this is where I excel
          </p>
        </v-flex>
      </v-layout>
      <v-layout class="my-5 mbbig" row wrap align-center justify-center>
        <v-flex xs12 md6 class="px-1">
          <p
            :style="textPadding"
            class="about-me-text margin-left text-right mb-50"
          >
            Another great part of product design is the fact that the idea
            shapes itself throughout it’s making. it gives me the ability to
            express myself not only with the design itself but through the whole
            experience.
          </p>
        </v-flex>
        <v-flex xs12 md6>
          <v-img
            v-if="$vuetify.breakpoint.width <= '960'"
            class="ml-5 mobile-m-right"
            contain
            :src="about3M"
            alt="Itay's pictture"
          ></v-img>

          <v-img
            v-else
            class="mx-auto"
            max-width="616px"
            max-height="548px"
            contain
            :src="about3"
            alt="Itay's pictture"
          ></v-img>
        </v-flex>
      </v-layout>
      <v-layout class="my-5 mbbig" row wrap align-center justify-center>
        <v-flex xs12 md6 order-xs2>
          <v-img
            v-if="$vuetify.breakpoint.width <= '960'"
            class="mobile-m-left"
            contain
            :src="about4M"
            alt="Itay's pictture"
          ></v-img>
          <v-img
            v-else
            class="mx-auto"
            max-width="608px"
            max-height="411px"
            contain
            :src="about4"
            alt="Itay's pictture"
          ></v-img>
        </v-flex>
        <v-flex xs12 md6 order-md2>
          <p
            :style="textPadding"
            class="about-me-text margin-right text-left mb-50"
          >
            I preffer my burger with cheese & onion jam on it. It adds the best
            flavour ever to any burger! 🍔
          </p>
        </v-flex>
      </v-layout>
      <v-layout class="my-5" row wrap align-center justify-center>
        <v-flex xs12 md6>
          <p
            :style="textPadding"
            class="about-me-text margin-left text-right mb-50"
          >
            Football is my way of spending my energies. The feeling, when you
            get on the pitch and play with friends - it’s irreplaceable.
          </p>
        </v-flex>
        <v-flex xs12 md6>
          <v-img
            v-if="$vuetify.breakpoint.width <= '960'"
            class="ml-5 mobile-m-right mobileMBottom"
            contain
            :src="about5M"
            alt="Itay's pictture"
          ></v-img>
          <v-img
            v-else
            class="mx-auto"
            max-width="572px"
            max-height="433px"
            contain
            :src="about5"
            alt="Itay's pictture"
          ></v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<style scoped>
#about {
  position: relative;
  z-index: 2;
}

.break {
  display: block;
}
.mbbig {
  padding-bottom: 150px;
}
.header_aboutme {
  padding-top: 100px;
}
.main-text {
  display: block;
  text-align: left;
  font-family: Futura-pt;
  font-size: 65px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-text {
  display: block;
  text-align: left;
  font-family: Futura-pt;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.88;
  letter-spacing: -0.82px;
  color: #959595;
}
.about-me-page {
  background-color: #0b0b0b;
}
.card-about-me {
  background-color: #0b0b0b;
}
.about-me-text {
  font-family: Futura-pt;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.58px;
  color: #ffffff;
}
.m-left {
  text-align: left;
}
.m-right {
  text-align: right;
}
.mobile-m-right {
  margin-right: -5%;
}
.mobile-m-left {
  margin-left: -5%;
}
@media (max-width: 600px) {
  .margin-right {
    margin-right: 80px !important;
  }
  .margin-left {
    margin-left: 80px !important;
  }
  .break {
    display: inline;
  }
  .header_aboutme {
    padding-top: 90px;
  }
  .mbbig {
    padding-bottom: 120px !important;
  }
  .mobileMBottom {
    margin-bottom: 50px;
  }
  .main-text {
    padding: 0px 30px;
    font-size: 46px;
  }
  .sub-text {
    padding: 0px 10px;
  }
}
@media (max-width: 960px) {
  .main-text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 46px;
    line-height: 1.24;
  }
  .sub-text {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.46;
    letter-spacing: -0.58px;
  }
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }
  .about-me-text {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.58px;
    color: #ffffff;
  }
  .margin-right {
    margin-right: 300px;
  }
  .margin-left {
    margin-left: 300px;
  }
  .mb-50 {
    margin-bottom: 52px;
  }
  .mbbig {
    padding-bottom: 200px;
  }
  .mobileMBottom {
    margin-bottom: 50px;
  }
}
@media (min-width: 1920px) {
  .main-text {
    margin-left: 150px;
  }
  .sub-text {
    margin-left: 150px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      about1: require("@assets/images/about/aboutMe1.png"),
      about1M: require("@assets/images/about/aboutMeM1.png"),

      about2: require("@assets/images/about/aboutMe2.png"),
      about2M: require("@assets/images/about/aboutMeM2.png"),

      about3: require("@assets/images/about/aboutMe3.png"),
      about3M: require("@assets/images/about/aboutMeM3.png"),

      about4: require("@assets/images/about/aboutMe4.png"),
      about4M: require("@assets/images/about/aboutMeM4.png"),

      about5: require("@assets/images/about/aboutMe5.png"),
      about5M: require("@assets/images/about/aboutMeM5.png")
    };
  },
  computed: {
    textPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { padding: "0px !important" };
        case "sm":
          return { padding: "0px !important" };
        case "md":
          return { padding: "0px 30px !important" };
        case "lg":
          return { padding: "0px 100px !important" };
        case "xl":
          return { padding: "0px 200px !important" };
        default:
          return { padding: "0px 50px !important" };
      }
    }
  }
};
</script>
