<template>
  <div class="about-me-page">
    <!-- <Header /> -->
    <Logo />
    <Sidebar />
    <div class="black-box-container">
      <About />
    </div>
    <Footer />
  </div>
</template>

<script>
// import Header from "@components/_header.vue";
import Logo from "@components/_logo.vue";
import Sidebar from "@components/_sidebarNav.vue";
import About from "@components/_about.vue";
import Footer from "@components/_footer.vue";

export default {
  data() {
    return {
      //
    };
  },
  components: {
    // Header,
    Logo,
    Sidebar,
    About,
    Footer
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }
};
</script>

<style scoped>
.about-me-page {
  position: relative;
}

.black-box-container {
  position: relative;
  width: 100%;
  background-color: #0b0b0b;
  z-index: 5;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
